<template>
  <div class="app-container">
    <ExtendedCrudTable
      ref="table"
      :entity="entity"
      :entity-store="entityStore"
      :columns="columns"
      :generate-summary="generateSummary"
      :definition="definition"
      :disabled="!hasReadPermission"
      parent-id-filter-field="dossierID"
      :create-item-from-form-data="createItemFromFormData"
      :create-form-data-from-item="createFormDataFromItem"
      :open-in-fullscreen="openInFullscreen"
    />
  </div>
</template>
<script>
import crudIndexMixin from '@/mixins/crud-index';
import EmbeddedCrudTable from '@/components/crud/EmbeddedCrudTable';
import formMixin from '@/pages/dossiers/loans/form-mixin';
import { hasReadPermissionForCode } from '@/utils/permission';
import { getFormDefinition, createItemFromFormData, createFormDataFromItem } from './form';

const ExtendedCrudTable = {
  itemFilter: l => l.dossierID === this.dossierID,
  name: 'ExtendedCrudTable',
  extends: EmbeddedCrudTable,
  methods: {
    showCreate() {
      if (this.disabled) {
        return false;
      }
      this.$router.push({ name: 'CreateLoan', params: { dossierID: this.$route.params.id } });
      this.$emit('create');
    },
    showEdit(id) {
      if (this.disabled) {
        return false;
      }
      this.$router.push({ name: 'EditLoan', params: { id: id, dossierID: this.$route.params.id } });
      this.$emit('edit');
    }
  }
};
export default {
  components: {
    ExtendedCrudTable
  },
  mixins: [crudIndexMixin, formMixin],
  props: {
    openInFullscreen: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      columns: [
        { field: 'loanTypeName', label: 'loan.loanType' },
        { field: 'id', label: 'loan.actNumber' },
        { field: 'requestDate', label: 'loan.requestDate', type: 'date', width: 160 },
        { field: 'statusName', label: 'loan.status' },
        { field: 'applicantName', label: 'loan.applicant' },
        { field: 'firstDate', label: 'loan.firstDate', type: 'date' },
        { field: 'amount', label: 'loan.amount', type: 'currency' },
        { field: 'repaymentAmount', label: 'loan.repaymentAmount', type: 'currency' },
        { field: 'terms', label: 'loan.terms', width: 120 },
        { field: 'interest', label: 'loan.interest', type: 'percentage' },
        { field: 'thlDate', label: 'loan.thlDate', type: 'date', width: 120 },
        { field: 'reasonName', label: 'loan.reason' }
      ]
    };
  },
  computed: {
    dossierID() {
      return parseInt(this.$route.params.id);
    },
    hasReadPermission() {
      return hasReadPermissionForCode(this.entity, this.$store.getters.permissions);
    },
    definition() {
      return getFormDefinition(this);
    }
  },
  methods: {
    generateSummary({ id, loanTypeName }) {
      let summary = `${id}`;
      if (loanTypeName) {
        summary += ` (${loanTypeName}, ${id})`;
      }
      return summary;
    }
  },
  createItemFromFormData() {
    return createItemFromFormData(this);
  },
  createFormDataFromItem
};
</script>

<style scoped></style>
