import MDinput from '@/components/MDinput';
import Select from '@/components/DSE/Select';
import DSESelect from '@/components/DSE/DSESelect';
import TextArea from '@/components/DSE/TextArea';
import { validatorRequire } from '@/utils/validators';
import { Checkbox, DatePicker, Divider } from 'element-ui';
import DossierHeaderCard from '@/pages/dossiers/DossierHeaderCard';
import LoanList from '@/pages/dossiers/loans/index';
import DocumentList from '@/pages/dossiers/documents/index';
import ActionList from '@/pages/dossiers/dossieractions/index';

export function getFormDefinition(vue) {
  return {
    saveButtons: [true],
    showFormLabels: [true, false, false, false],
    names: ['Lener', 'Leningen', 'Acties', 'Documenten'],
    labels: ['tabs.lener', 'tabs.leningen', 'tabs.acties', 'tabs.documenten'],
    labelwidth: ['150px', '150px', '150px', '150px'],
    header: form => {
      return {
        type: DossierHeaderCard,
        props: {
          dossier: form
        }
      };
    },
    groups: [
      {
        id: {
          type: MDinput,
          props: {
            readonly: true,
            caption: 'dossier.dossierID'
          }
        },
        lener: {
          type: Divider,
          props: {
            content: 'lener'
          }
        },
        initials: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 16,
            required: true
          },
          rules: [{ validator: validatorRequire }]
        },
        middleName: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 16,
            required: false
          }
        },
        lastName: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: true
          },
          rules: [{ validator: validatorRequire }]
        },
        genderLenerID: {
          type: DSESelect,
          defaultvalue: 1,
          props: {
            caption: 'common.gender',
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['gender/isLoading'],
            options: vue.$store.getters['gender/loadedItems']
          },
          rules: [{ validator: validatorRequire }]
        },
        dateOfBirth: {
          type: DatePicker,
          defaultValue: '',
          props: {
            format: 'dd-MM-yyyy',
            required: true
          },
          rules: [{ validator: validatorRequire }]
        },
        telephone: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: false
          }
        },
        mobile: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: false
          }
        },
        email: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: true,
            type: 'email'
          },
          rules: [{ validator: validatorRequire }]
        },
        personelnumber: {
          type: MDinput,
          props: {
            caption: 'dossier.personeelsNummer',
            maxlength: 16,
            required: true
          },
          rules: [{ validator: validatorRequire }]
        },
        uniquePersonelnumber: {
          type: MDinput,
          props: {
            caption: 'dossier.uniekpersoneelsNummer',
            maxlength: 16,
            required: false
          }
        },
        partner: {
          type: Divider,
          props: {
            content: 'lener'
          }
        },
        initialsPartner: {
          type: MDinput,
          defaultValue: '',
          props: {
            caption: 'common.initials',
            maxlength: 16,
            required: false
          }
        },
        middleNamePartner: {
          type: MDinput,
          defaultValue: '',
          props: {
            caption: 'common.middleName',
            maxlength: 16,
            required: false
          }
        },
        lastNamePartner: {
          type: MDinput,
          defaultValue: '',
          props: {
            caption: 'common.lastName',
            maxlength: 64,
            required: false
          }
        },
        genderPartnerID: {
          type: DSESelect,
          props: {
            caption: 'common.gender',
            selectFirstOnDefault: false,
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['gender/isLoading'],
            options: vue.$store.getters['gender/loadedItems']
          }
        },
        dateOfBirthPartner: {
          type: DatePicker,
          props: {
            caption: 'common.dateOfBirth',
            required: false,
            format: 'dd-MM-yyyy'
          }
        },
        mobilePartner: {
          type: MDinput,
          defaultValue: '',
          props: {
            caption: 'common.mobile',
            maxlength: 64,
            required: false
          }
        },
        emailPartner: {
          type: MDinput,
          defaultValue: '',
          props: {
            caption: 'common.email',
            maxlength: 64,
            required: false,
            type: 'email'
          }
        },
        address: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: false
          }
        },
        postcode: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 16,
            required: false
          }
        },
        city: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: false
          }
        },
        workInfo: {
          type: Divider
        },
        companyID: {
          type: DSESelect,
          props: {
            caption: 'dossier.company',
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['company/isLoading'],
            options: vue.$store.getters['company/loadedItems']
          }
        },
        contractType: {
          type: Select,
          defaultvalue: 2,
          props: {
            selectfirstondefault: true,
            options: contractTypeOptions
          }
        },
        enddateContract: {
          type: DatePicker,
          props: {
            caption: 'dossier.enddateContract',
            required: false,
            format: 'dd-MM-yyyy'
          }
        },
        salaryScale: {
          type: MDinput,
          props: {
            caption: 'dossier.salaryscale'
          }
        },
        functionID: {
          type: DSESelect,
          props: {
            caption: 'dossier.function',
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['applicantfunction/isLoading'],
            options: vue.$store.getters['applicantfunction/loadedItems']
          }
        },
        bCcodeID: {
          type: DSESelect,
          props: {
            caption: 'dossier.bcCode',
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['bccode/isLoading'],
            options: vue.$store.getters['bccode/loadedItems']
          }
        },
        archive: {
          type: Checkbox,
          props: {
            required: true,
            saveAndReloadDataOnChange: true
          }
        },
        archiveDate: {
          type: DatePicker,
          props: {
            caption: 'dossier.archiveDate',
            readonly: true,
            format: 'dd-MM-yyyy'
          }
        },
        remarks: {
          type: TextArea,
          props: {
            caption: 'dossier.remarks',
            maxlength: 1000
          }
        }
      },
      {
        leningen: {
          type: LoanList,
          props: {
            labelWidth: '0px',
            caption: 'common.emptyString',
            entitystore: 'loan',
            filters: {}
          }
        }
      },
      {
        actions: {
          type: ActionList,
          props: {
            labelWidth: '0px',
            caption: 'common.emptyString',
            entitystore: 'dossieraction'
          }
        }
      },
      {
        documents: {
          type: DocumentList,
          props: {
            labelWidth: '0px',
            caption: 'common.emptyString',
            entitystore: 'document'
          }
        }
      }
    ]
  };
}

export default getFormDefinition;

const layoutFullWidth = {
  sections: [
    {
      width: 100
    }
  ]
};
const layoutGeneral = {
  named: true,
  sections: [
    {
      width: 50,
      fields: [
        'lener',
        'initials',
        'middleName',
        'lastName',
        'genderLenerID',
        'dateOfBirth',
        'telephone',
        'mobile',
        'email',
        'address',
        'postcode',
        'city',
        'workInfo',
        'companyID',
        'contractType',
        'enddateContract',
        'salaryScale',
        'personelnumber',
        'uniquePersonelnumber',
        'functionID',
        'bCcodeID',
        'archive',
        'archiveDate',
        'remarks'
      ]
    },
    {
      width: 50,
      fields: [
        'partner',
        'initialsPartner',
        'middleNamePartner',
        'lastNamePartner',
        'genderPartnerID',
        'dateOfBirthPartner',
        'mobilePartner',
        'emailPartner'
      ]
    }
  ]
};

export function getCreateLayoutDefinition(vue) {
  return {
    groups: [layoutGeneral, layoutFullWidth, layoutFullWidth]
  };
}
export function getEditLayoutDefinition(vue) {
  return {
    groups: [layoutGeneral, layoutFullWidth, layoutFullWidth, layoutFullWidth]
  };
}

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, dossier) {
  const form = {
    ...dossier
  };
  return form;
}
export function contractTypeOptions() {
  return [
    { id: 1, value: 'Bepaald' },
    { id: 2, value: 'Onbepaald' },
    { id: 3, value: 'Onbekend' },
    { id: 4, value: 'Gepensioneerd' }
  ];
}
