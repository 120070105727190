<template>
  <div>
    <el-col v-if="headerTemplate" :xs="24" :sm="18" :md="12" :lg="8" :xl="6" class="createPost-header">
      <el-card v-loading="pageLoading">
        <div class="" v-html="headerTemplate"></div>
      </el-card>
    </el-col>
    <component
      :is="header.type || 'TabbedEditorHeaderCard'"
      v-if="header"
      class="createPost-header"
      :loading="pageLoading"
      v-bind="header.props"
    />
  </div>
</template>
<script>
import TabbedEditorHeaderCard from './TabbedEditorHeaderCard';
export default {
  components: {
    TabbedEditorHeaderCard
  },
  props: {
    headerTemplate: String,
    header: {
      type: Object,
      default: () => {}
    },
    pageLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
